import React from 'react'

const breakString = (str, hasSpan = true, symb, className = '') => {
    let arr = str.split(symb || '</br>');

    if (!hasSpan) {
        return arr
    }

    return arr.map((i, k) => (
        <span className={`${className || 'item'}-${k+1}`} key={k}>{i}</span>
    ))

}

export default breakString